//import {useRouter} from "next/router";

const IndexPage = () => {
  // const router = useRouter()
  // const { query } = router

  return (
    <>
      <div></div>
    {/*  <ul>*/}
    {/*    {Object.entries(query).map(([key, value]) => (*/}
    {/*      <li key={key}>*/}
    {/*        {key}: {value}*/}
    {/*      </li>*/}
    {/*    ))}*/}
    {/*  </ul>*/}
    {/*<AtlassianPluginsUiKit/>*/}
    </>

  );
};

export default IndexPage;
